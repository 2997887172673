import React, { Component } from 'react'
import axios from 'axios'
import InfiniteScroll from 'react-infinite-scroll-component';
import 'semantic-ui/dist/semantic.min.css';
import { Grid, Loader, Card, Image, Icon, Button } from 'semantic-ui-react'
import Moment from 'react-moment';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import Linkify from 'react-linkify';
import {create_page_visit} from './logPageVisit';
import { Link } from 'react-router-dom';

class TeamPosts extends Component {

  constructor(props) {
    super(props)
    this.state = {
      items: null,
      loadMore: false,
      search_metadata: {},
      complete_message: ''
    }
    this.fetchMoreData = this.fetchMoreData.bind(this);
    this.get_all_tweets = this.get_all_tweets.bind(this);
  }

  componentDidMount() {
    this.get_all_tweets();
    this._ismounted = true;
  }

  get_all_tweets() {
    axios.get('https://radon-api.herokuapp.com/api/v1/tweets?team=true')
    .then(response => {
      if(response.data.tweets.statuses.length >0){
        this.setState({items: response.data.tweets.statuses,
                       search_metadata: response.data.tweets.search_metadata,
                       loadMore: true});
      } else {
        this.setState({complete_message: 'No recent team tweets available!', loadMore: false, items: []})
      }
    })
    .catch(error => console.log(error))
  }

  fetchMoreData(){
    console.log('fetch');
    var url = 'https://api.twitter.com/1.1/search/tweets.json' + this.state.search_metadata.next_results
    axios.get('https://radon-api.herokuapp.com/api/v1/tweets/next_page', { params: {next_results: url}})
    .then(response => {
      if(response.data.tweets.statuses.length === 0) { this.setState({loadMore: false, complete_message: "Yay! You've seen it all."}); }
      else {
        var joined_tweets = this.state.items.concat(response.data.tweets.statuses);
        this.setState({items: joined_tweets, search_metadata: response.data.tweets.search_metadata});
      }
    })
    .catch(error => {
      console.log(error);
    })
  };

  get_likes(count) {
    if(count > 0){
      return count;
    }
    else return ''
  }

  getContent(status) {
    if(status.retweeted_status){
      return (
        <div>
          <span className='display-inline'><p className='gray-text'>{status.user.name}</p>&nbsp;Retweeted</span>
          <br/><br/>
          <Linkify>
            <span className='medium-font'>{status.retweeted_status.full_text}</span>
            <br/>
            {this.getImages(status.retweeted_status)}
          </Linkify>
        </div>
        );
    }
    else {
      return (<Linkify>{status.full_text}</Linkify>);
    }
  }

  getImages(status){
    if(status.extended_entities && status.extended_entities.media.length > 0){
      return status.extended_entities.media.map((item, index) => (
          (item.video_info) ?  <video controls loop src={item.video_info.variants[0].url} 
                                      autoplay muted /> : <Image src={item.media_url} />
      ));

    }
  }

  redirect_to(url) {
    window.location = url
  }

  render() {
    if(!this.state.items) return  <Loader active size='big' inline='centered' className='margin-top-20' />
    return (
      <div>
        <center>
        <Link to="/all_tweets">
            <Button basic color='grey'> ALL </Button>
          </Link>
          <Button basic color='grey' disabled>
            TEAM
          </Button>
        </center>
        <hr />
        <InfiniteScroll
          dataLength={this.state.items.length}
          next={this.fetchMoreData}
          hasMore={this.state.loadMore}
          loader={<Loader active inline='centered' />}
          endMessage={
            <p style={{textAlign: 'center'}}>
              <b>{this.state.complete_message}</b>
            </p>
          }
        >
          {this.state.items.map((status, index) => (
            <Card key={index} className='tweet-card'>
              <Card.Content>
                <Image floated='right' size='mini' src={status.user.profile_image_url} />
                <Card.Header>{status.user.name}<Icon name='twitter' style={{color: '#14adc5'}}/></Card.Header>
                <Card.Meta><Moment format="MMMM DD, YYYY">{status.created_at}</Moment></Card.Meta>
                <Card.Description>
                  {this.getContent(status)}
                  <br/>
                  {this.getImages(status)}
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <Grid columns={3} centered>
                  <Grid.Row verticalAlign='middle'>
                    <Grid.Column style={{textAlign: 'center', cursor: 'pointer'}}>
                      <div onClick={() => {this.redirect_to("https://twitter.com/intent/tweet?in_reply_to="+status.id_str)}}>
                        <Icon disabled name='comment outline' style={{fontSize: '1.5rem'}} />
                      </div>
                    </Grid.Column>
                    <Grid.Column style={{textAlign: 'center', cursor: 'pointer'}}>
                      <div onClick={() => {this.redirect_to("https://twitter.com/"+ status.user.screen_name +"/status/"+status.id_str)}}>
                        <Icon disabled name='retweet' style={{fontSize: '1.5rem'}} className={status.retweeted ? 'green' : null} />
                        <span>{this.get_likes(status.retweet_count)}</span>
                      </div>
                    </Grid.Column>
                    <Grid.Column style={{textAlign: 'center', cursor: 'pointer'}}>
                      <div onClick={() => {this.redirect_to("https://twitter.com/"+ status.user.screen_name +"/status/"+status.id_str)}}>
                        <Icon disabled name='heart' style={{fontSize: '1.5rem'}} className={status.favorited ? 'red' : null} />
                        <span>{this.get_likes(status.favorite_count)}</span>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card.Content>
            </Card>
          ))}
        </InfiniteScroll>
        <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER}/>
      </div>
    );
  }
}

export default TeamPosts