import axios from 'axios'

export const create_page_visit=(url)=>{
  console.log(url);
  axios.post('https://radon-api.herokuapp.com/api/v1/stats/page_visit', {url: url})
    .then(response => {
      console.log(response);
    })
    .catch(error => {
      console.log(error);
    })
}
