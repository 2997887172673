import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import TwitterLogin from 'react-twitter-auth/lib/react-twitter-auth-component.js';
import Dinner from '../radon-house.jpg';
import LogoDark from '../logo-dark.png';
import '../App.css';
import axios from 'axios'
import 'semantic-ui/dist/semantic.min.css';
import { Grid, Button } from 'semantic-ui-react'

class Index extends Component {

  constructor() {
    super();
    this.state = { isAuthenticated: false, user: null};
    this.onSuccess = this.onSuccess.bind(this);
    this.initialize_user = this.initialize_user.bind(this);
    this.logout = this.logout.bind(this);
    this.setTimer = false;
  }

  componentDidMount() {
    if(localStorage.getItem('auth_token')){
      axios.defaults.headers.common['Authorization'] = localStorage.getItem('auth_token');
      this.initialize_user();
      if (this.setTimer == false || this.props.onTimer == false) {
        this.props.startTimer();
        this.setTimer = true;
      }
    }
  }

  initialize_user(){
    axios.get('https://radon-api.herokuapp.com/api/v1/get_current_user')
    .then(response => {
      this.setState({user: response.data.user, isAuthenticated: true})
    })
    .catch(error => {
      localStorage.removeItem('auth_token');
      window.location = '/';
    })
  }

  onFailed(error) {
    alert(error);
  }

  onSuccess(response) {
    response.json().then(json => {
      console.log(json.user);
      localStorage.setItem('auth_token', json.auth_token);
      axios.defaults.headers.common['Authorization'] = json.auth_token;
      this.setState({isAuthenticated: true, user: json.user});
    });
  }

  logout() {
    console.log('logout');
    localStorage.removeItem('auth_token');
    this.setState({isAuthenticated: false, user: null});
    this.props.alertUser();
    this.setState({ interval: 0});
  };


  render() {
    
    return (
      <div>
        <center>
          <img src={Dinner} alt="Dinner" className='dinner_image' />
          <br/><br/>
          <Grid columns='equal'>
            <Grid.Row>
              <Grid.Column width={2}>
              </Grid.Column>
              <Grid.Column width={4}>
              <Link to="/news">
                <Button basic content='IN THE NEWS' />
              </Link>
              </Grid.Column>
              <Grid.Column width={4} style={{'padding_left': '0.5rem'}}>
              <Link to="/team_posts">
                <Button basic content='RESEARCHER UPDATES' />
              </Link>
              </Grid.Column>
              <Grid.Column width={4}>
                <Link to="/all_tweets">
                  <Button basic content='TWEETS ABOUT RADON' />
                </Link>
              </Grid.Column>
              <Grid.Column width={1}>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
            <Grid.Column width={2}>
              </Grid.Column>
            <Grid.Column>
              </Grid.Column>
              <Grid.Column>
              <a href="https://forms.gle/rhTT5MCLZ4HMPRrE9" target="_blank">
                <Button content='GET A FREE RADON TEST KIT' style={{'width': 'fit-content'}} className='test-kit-button' basic />
              </a>
              </Grid.Column>
              <Grid.Column>
              </Grid.Column>
              <Grid.Column width={2}>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <br/>
        </center>
        <Grid columns={1}>
        </Grid>
      </div>
    );
  }
}

export default Index;
