import React, { Component } from 'react';
import './App.css';
import axios from 'axios';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Index from './components/Index'
import News from './components/News'
import TeamPosts from './components/TeamPosts'
import AllTwitter from './components/AllTwitter'
import TestKit from './components/TestKit'
import Stats from './components/Stats'
import UserSessions from './components/UserSessions';

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Route path="/" exact component={Index} />
          <Route path="/news" exact component={News} />
          <Route path="/team_posts" exact component={TeamPosts} />
          <Route path="/all_tweets" exact component={AllTwitter} />
          <Route path="/testkit" exact component={TestKit} />
          <Route path="/stats" exact component={Stats} />
        </div>
      </Router>
    );
  }
}
export default App;
