import React, { Component } from 'react';
import '../App.css';
import axios from 'axios'
import 'semantic-ui/dist/semantic.min.css';
import { Button, Form } from 'semantic-ui-react'
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import {create_page_visit} from './logPageVisit';

class Index extends Component {

  constructor() {
    super();
    this.state = { user: null,
                   ordered: false,
                   is_loading: false,
                   form: {
                           first_name: '',
                           last_name: '',
                           street_address: '',
                           city: '',
                           state: '',
                           zip: '',
                           email: '',
                           phone: ''
                          }
                  };
    this.setTimer = false;
    this.initialize_user = this.initialize_user.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    if(localStorage.getItem('auth_token')) {
      axios.defaults.headers.common['Authorization'] = localStorage.getItem('auth_token');
      this.initialize_user()
      create_page_visit(this.props.location.pathname);
      if (this.setTimer == false || this.props.onTimer == false) {
        this.props.startTimer();
        this.setTimer = true;
      }
    }
  }

  initialize_user(){
    axios.get('https://radon-api.herokuapp.com/api/v1/get_current_user')
    .then(response => {
      this.setState({user: response.data.user})
      if(response.data.orders_count>0){this.setState({ordered: true})}
    })
    .catch(error => {
      ToastsStore.info("Oops. Looks like you're not signed in!");
      localStorage.removeItem('auth_token');
      window.location = '/';
    })
  }

  display_errors(res){
    ToastsStore.error("Can't submit form, "+res.data.errors.base[0]);
  }

  success_message(){
    this.setState({ form: { ...this.state.form, first_name: ''} });
    this.setState({ form: { ...this.state.form, last_name: ''} });
    this.setState({ form: { ...this.state.form, street_address: ''} });
    this.setState({ form: { ...this.state.form, city: ''} });
    this.setState({ form: { ...this.state.form, state: ''} });
    this.setState({ form: { ...this.state.form, zip: ''} });
    this.setState({ form: { ...this.state.form, email: ''} });
    this.setState({ form: { ...this.state.form, phone: ''} });
    this.setState({is_loading: false, ordered: true});
    ToastsStore.success('Order successfully placed!');
  }

  handleFormSubmit(e){
    this.setState({is_loading: true});
    axios.post('https://radon-api.herokuapp.com/api/v1/users/new_kit', {form: this.state.form})
    .then(response => {
      console.log(response);
      if(response.data.errors){
        this.display_errors(response);
      }
      else this.success_message();
    })
    .catch(error => {
      console.log(error.data);
    })
  }

  handleChange(e, { name, value }){
    let formValues = this.state.form;
    formValues[name] = value;

    this.setState({formValues})
  }

  render() {
    if(!this.state.user) return null

    return (
      <div>
        <center>
          <p className='gray-text'>ORDER A TEST KIT</p>
          <p className='gray-text'>Complete the form to order for free</p>
          <hr />
          {this.state.ordered ? (
            <h4>You can order a kit for a friend or family member as well!</h4>
          ) : (
            null
          )}
           <Form style={{width: '90%'}} onSubmit={this.handleFormSubmit} loading={this.state.is_loading}>
            <Form.Group unstackable>
              <Form.Input
                required
                value={this.state.form.first_name}
                width={8}
                fluid
                onChange={this.handleChange}
                id='input-first-name'
                placeholder='First name'
                name='first_name'
              />
              <Form.Input
                required
                name='last_name'
                value={this.state.form.last_name}
                width={8}
                fluid
                onChange={this.handleChange}
                id='input-last-name'
                placeholder='Last name'
              />
            </Form.Group>
            <br/>
            <Form.Group unstackable>
              <Form.Input
                required
                name='street_address'
                value={this.state.form.street_address}
                width={16}
                fluid
                onChange={this.handleChange}
                id='input-street-address'
                placeholder='Street Address'
              />
            </Form.Group>
            <br/>
            <Form.Group unstackable>
              <Form.Input
                required
                name='city'
                value={this.state.form.city}
                width={16}
                fluid
                onChange={this.handleChange}
                id='input-city'
                placeholder='City'
              />
            </Form.Group>
            <br/>
            <Form.Group unstackable>
              <Form.Input
                required
                name='state'
                value={this.state.form.state}
                width={10}
                fluid
                onChange={this.handleChange}
                id='input-state'
                placeholder='State'
              />
              <Form.Input
                required
                name='zip'
                value={this.state.form.zip}
                width={6}
                fluid
                onChange={this.handleChange}
                id='input-zip'
                placeholder='Zip'
              />
            </Form.Group>
            <br/>
            <Form.Group unstackable>
              <Form.Input
                type='email'
                required
                name='email'
                value={this.state.form.email}
                width={16}
                fluid
                onChange={this.handleChange}
                id='input-email'
                placeholder='Email'
              />
            </Form.Group>
            <br/>
            <Form.Group unstackable>
              <Form.Input
                required
                name='phone'
                value={this.state.form.phone}
                width={10}
                fluid
                onChange={this.handleChange}
                id='input-phone'
                placeholder='Phone'
              />
              <Button>Submit</Button>
            </Form.Group>
          </Form>
        </center>
        <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER}/>
      </div>
    );
  }
}

export default Index;
