import React, { Component, useEffect, useState } from 'react'
import axios from 'axios';
import { Loader } from 'semantic-ui-react'
import InfiniteScroll from 'react-infinite-scroll-component';
import './UserSessions.css'

function UserSession(props) {
  const [user, setUser] = useState(null);
  const [userSessions, setUserSessions] = useState([]);
  const [loadMore, setLoadMore] = useState(false);
  const [limit, setLimit] = useState(40);
  const [offSet, setOffSet] = useState(0);
  const [timer, setTimer] = useState(false);

  useEffect(() => {
    if(localStorage.getItem('auth_token')){
      axios.defaults.headers.common['Authorization'] = localStorage.getItem('auth_token');
      initialize_user()
      setOffSet(0);
      get_users_session();
      if (timer == false || props.onTimer == false) {
        props.startTimer();
        setTimer(true);
      }
    }
    
  }, [])
  const get_users_session = () => {
    axios.get('https://radon-api.herokuapp.com/api/v1/activity_log', { params: {limit: limit, offset: offSet}})
    .then(response => {
      if(response){
        setUserSessions(response.data.user_sessions);
        if(response.data.user_sessions.length < limit) {
          setLoadMore(false);  
        } else {
          setLoadMore(true);
          setOffSet(offSet+limit);
        }
      }
    })
    .catch(error => console.log(error))
  }
  const fetchMore = () => {
    axios.get('https://radon-api.herokuapp.com/api/v1/activity_log', { params: {limit: limit, offset: offSet}})
    .then(response => {
      if(response.data.user_sessions.length>0){
        let joined = userSessions.concat(response.data.user_sessions);
        setUserSessions(joined);

        if(response.data.user_sessions.length < limit) {
          setLoadMore(false);  
        } else {
          setLoadMore(true);
          setOffSet(offSet+limit);
        }
      }
    })
    .catch(error => console.log(error))
  }
  const initialize_user = () => {
    axios.get('https://radon-api.herokuapp.com/api/v1/get_current_user')
    .then(response => {
      setUser(response.data.user);
      //this.setState({user: response.data.user})
    })
    .catch(error => {
      localStorage.removeItem('auth_token');
      window.location = '/';
    })
  }
  const toTime = (seconds) => {
    var date = new Date(null);
    date.setSeconds(seconds);
    return date.toISOString().substr(11, 8);
  }

  if(!user || !userSessions) {
    return(  
      <Loader active size='big' inline='centered' className='margin-top-20' />
    )
  } else {
    // if(this.setTimer == false || this.props.onTimer == false){
    //   this.props.startTimer();
    //   this.setTimer = true;
    // }
    return (
      <div>
        <div className='container'>
          <InfiniteScroll
            dataLength={userSessions.length}
            next={fetchMore}
            hasMore={loadMore}
            loader={<Loader active inline='centered' />}
            endMessage={
              <p style={{textAlign: 'center'}}>
                <b>You have seen it all!</b>
              </p>
            }
          >
            <table className="table" id="session-table">
              <thead>
                  <tr>
                      {/* <th>User_Id</th> */}
                      <th id="session-head">User_Name</th>
                      <th id="session-head">Time Spent</th>
                      <th id="session-head">Date</th>
                  </tr>
              </thead>
              <tbody>
                {userSessions.map((session)=>{
                  return(
                    <tr scope="row">
                        {/* <td>{card.user_id}</td> */}
                        <td id="session-data">{session.name}</td>
                        {/* <td>{session.time_spent}</td> */}
                        <td id="session-data">{toTime(session.time_spent)}</td>
                        <td id="session-data">{session.log_date}</td>
                    </tr>
                  )}
                )}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      </div>
    )
  }
}

export default UserSession
